import styled,{keyframes}from'styled-components';export const Center=styled.div`
  padding: 2em;
  text-align: center;
`;// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const motion=props=>keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;export const DualRingSpinner=styled.div`
  display: inline-block;
  width: ${p=>`${p.width}${p.sizeUnit}`};
  height: ${p=>`${p.height}${p.sizeUnit}`};

  ::after {
    content: ' ';
    display: block;
    animation: ${p=>motion(p)} 1.2s linear infinite;
    margin: 1px;
    border: 5px solid ${p=>p.color};
    border-color: ${p=>p.color} transparent ${p=>p.color} transparent;
    border-radius: 50%;
    width: ${p=>`${p.size}${p.sizeUnit}`};
    height: ${p=>`${p.size}${p.sizeUnit}`};
  }
`;