import _styled,{css as _css}from"styled-components";import{RGBToRGBA,Transparencies}from'@utils/theme';export const BaseButton=_styled.button.withConfig({displayName:"styles__BaseButton",componentId:"sc-1h8buj1-0"})(["outline:none;border:none;border-radius:0.8em;width:",";padding:",";background-color:transparent;cursor:pointer;font-size:",";font-weight:",";&:disabled{cursor:not-allowed;}"],_ref=>{let{width}=_ref;return width==='auto'?'fit-content':'100%';},_ref2=>{let{size}=_ref2;return size==='small'?'0.45em 1.5em':'1em 1.5em';},_ref3=>{let{theme}=_ref3;return theme.fonts.fontSizes.small;},_ref4=>{let{theme}=_ref4;return theme.fonts.fontWeights.bold;});const navigationButtonMixin=_css`
  border: 2px solid transparent;
  background-color: ${_ref5=>{let{theme}=_ref5;return theme.colors.secondary;}};
  color: white;

  &:hover {
    border: 2px solid ${_ref6=>{let{theme}=_ref6;return theme.colors.danger;}};
  }

  &:active,
  &:focus {
    border: 2px solid ${_ref7=>{let{theme}=_ref7;return theme.colors.primary;}};
  }

  &:disabled {
    border: 2px solid transparent;
    background-color: ${_ref8=>{let{theme}=_ref8;return theme.colors.disabled;}};
    color: ${RGBToRGBA('rgb(255,255,255)',Transparencies.P50)};
  }
`;const navigationButtonGhostMixin=_css`
  border: 2px solid ${_ref9=>{let{theme}=_ref9;return theme.colors.secondary;}};
  background-color: white;
  font-weight: ${_ref10=>{let{theme}=_ref10;return theme.fonts.fontWeights.regular;}};
  color: ${_ref11=>{let{theme}=_ref11;return theme.colors.secondary;}};

  &:hover {
    border: 2px solid ${_ref12=>{let{theme}=_ref12;return theme.colors.danger;}};
  }

  &:active,
  &:focus {
    border: 2px solid ${_ref13=>{let{theme}=_ref13;return theme.colors.primary;}};
    background-color: ${_ref14=>{let{theme}=_ref14;return RGBToRGBA(theme.colors.primary,Transparencies.P10);}};
  }

  &:disabled {
    border: 2px solid ${_ref15=>{let{theme}=_ref15;return theme.colors.disabled;}};
    background-color: transparent;
    color: ${_ref16=>{let{theme}=_ref16;return theme.colors.disabled;}};
  }
`;const contentButtonMixin=_css`
  border: 2px solid ${_ref17=>{let{theme}=_ref17;return theme.colors.primary;}};
  background-color: ${_ref18=>{let{theme}=_ref18;return theme.colors.primary;}};
  color: white;

  &:hover {
    border: 2px solid ${_ref19=>{let{theme}=_ref19;return theme.colors.danger;}};
  }

  &:active,
  &:focus {
    border: 2px solid ${_ref20=>{let{theme}=_ref20;return theme.colors.secondary;}};
  }

  &:disabled {
    border: 2px solid transparent;
    background-color: ${_ref21=>{let{theme}=_ref21;return RGBToRGBA(theme.colors.primary,Transparencies.P50);}};
    color: ${_ref22=>{let{theme}=_ref22;return theme.colors.disabled;}};
  }
`;const deleteButtonMixin=_css`
  border: 2px solid ${_ref23=>{let{theme}=_ref23;return theme.colors.danger;}};
  background-color: ${_ref24=>{let{theme}=_ref24;return theme.colors.danger;}};
  color: white;

  &:hover {
    background-color: white;
    color: ${_ref25=>{let{theme}=_ref25;return theme.colors.danger;}};
  }

  &:active,
  &:focus {
    border: 2px solid ${_ref26=>{let{theme}=_ref26;return theme.colors.danger;}};
  }
`;const contentButtonGhostMixin=_css`
  border: 2px solid ${_ref27=>{let{theme}=_ref27;return theme.colors.primary;}};
  background-color: transparent;
  font-weight: ${_ref28=>{let{theme}=_ref28;return theme.fonts.fontWeights.regular;}};
  color: ${_ref29=>{let{theme}=_ref29;return theme.colors.secondary;}};

  &:hover {
    border: 2px solid ${_ref30=>{let{theme}=_ref30;return theme.colors.danger;}};
  }

  &:active,
  &:focus {
    border: 2px solid ${_ref31=>{let{theme}=_ref31;return theme.colors.secondary;}};
  }

  &:disabled {
    border: 2px solid
      ${_ref32=>{let{theme}=_ref32;return RGBToRGBA(theme.colors.primary,Transparencies.P25);}};
    background-color: transparent;
    color: ${_ref33=>{let{theme}=_ref33;return RGBToRGBA(theme.colors.primary,Transparencies.P25);}};
  }
`;export const StyledButton=_styled(BaseButton).withConfig({displayName:"styles__StyledButton",componentId:"sc-1h8buj1-1"})(["",";"],_ref34=>{let{variation,flavour}=_ref34;switch(variation){case'standard':switch(flavour){case'navigation':return navigationButtonMixin;case'content':return contentButtonMixin;default:return contentButtonMixin;}case'ghost':switch(flavour){case'navigation':return navigationButtonGhostMixin;case'content':return contentButtonGhostMixin;default:return contentButtonGhostMixin;}case'delete':return deleteButtonMixin;default:return contentButtonMixin;}});