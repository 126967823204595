import styled from'styled-components';export const DropArea=styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: 2px dashed ${_ref=>{let{theme}=_ref;return theme.colors.primary;}};
  padding: 20px;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${_ref2=>{let{theme}=_ref2;return theme.colors.primary;}};
`;export const TextContainer=styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.3em;
`;export const TextItem=styled.p``;