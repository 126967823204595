import _styled,{css as _css}from"styled-components";const HeadlineField=_css`
  border-radius: 0.6em;
  height: 2.4em;
  padding: 0 0.8em;
  font-size: ${_ref=>{let{theme}=_ref;return theme.fonts.fontSizes.large;}};
`;const NormalField=_css`
  border-radius: 1em;
  height: 3em;
  padding: 0 1.2em;

  &::placeholder {
    font-size: ${_ref2=>{let{theme}=_ref2;return theme.fonts.fontSizes.extraSmall;}};
  }
`;export const Input=_styled.input.withConfig({displayName:"styles__Input",componentId:"sc-1dsqo8n-0"})(["opacity:",";box-shadow:",";outline:none;border:",";"," width:100%;line-height:1;color:",";&:active,&:focus{border:2px solid ",";padding-left:calc(1.2em - 1px);}"],_ref3=>{let{opaque}=_ref3;return opaque?'0.6':'1';},_ref4=>{let{theme}=_ref4;return theme.insetShadow;},_ref5=>{let{theme}=_ref5;return theme.inputBorder;},_ref6=>{let{variation}=_ref6;switch(variation){case'headline':return HeadlineField;default:return NormalField;}},_ref7=>{let{theme}=_ref7;return theme.colors.secondary;},_ref8=>{let{theme}=_ref8;return theme.colors.primary;});export const InputContainer=_styled.div.withConfig({displayName:"styles__InputContainer",componentId:"sc-1dsqo8n-1"})(["position:relative;width:100%;"]);