import _styled,{css as _css}from"styled-components";export const BaseIcon=_styled.i.withConfig({displayName:"styles__BaseIcon",componentId:"sc-bprjb9-0"})(["font-size:1.2em;"]);const extraExtraSmallIconMixin=_css`
  font-size: 0.5em;
`;const extraSmallIconMixin=_css`
  font-size: 1em;
`;const smallIconMixin=_css`
  font-size: 1.2em;
`;const mediumIconMixin=_css`
  font-size: 1.5em;
`;const largeIconMixin=_css`
  font-size: 1.9em;
`;const extraLargeIconMixin=_css`
  font-size: 2.5em;
`;const extraExtraLargeIconMixin=_css`
  font-size: 7em;
`;const lightColorIconMixin=_css`
  color: ${_ref=>{let{theme}=_ref;return theme.colors.disabled;}};
`;const primaryColorIconMixin=_css`
  color: ${_ref2=>{let{theme}=_ref2;return theme.colors.primary;}};
`;const dangerColorIconMixin=_css`
  color: ${_ref3=>{let{theme}=_ref3;return theme.colors.danger;}};
`;const baseColorIconMixin=_css`
  color: inherit;
`;const veryLightColorIconMixin=_css`
  color: ${_ref4=>{let{theme}=_ref4;return theme.colors.background;}};
`;const whiteColorIconMixin=_css`
  color: white;
`;const successColorIconMixin=_css`
  color: ${_ref5=>{let{theme}=_ref5;return theme.colors.success;}};
`;export const StyledIcon=_styled(BaseIcon).withConfig({displayName:"styles__StyledIcon",componentId:"sc-bprjb9-1"})(["",";",";"],_ref6=>{let{fontSize}=_ref6;switch(fontSize){case'extraExtraSmall':return extraExtraSmallIconMixin;case'extraSmall':return extraSmallIconMixin;case'small':return smallIconMixin;case'medium':return mediumIconMixin;case'large':return largeIconMixin;case'extraLarge':return extraLargeIconMixin;case'extraExtraLarge':return extraExtraLargeIconMixin;default:return smallIconMixin;}},_ref7=>{let{color}=_ref7;switch(color){case'light':return lightColorIconMixin;case'bright':return veryLightColorIconMixin;case'normal':return baseColorIconMixin;case'primary':return primaryColorIconMixin;case'danger':return dangerColorIconMixin;case'white':return whiteColorIconMixin;case'success':return successColorIconMixin;default:return baseColorIconMixin;}});