import styled from'styled-components';export const Wrapper=styled.div`
  grid-template-columns: 1fr;
  grid-gap: 1.5em;
  display: grid;
`;export const CardContent=styled.div`
  position: relative;
  grid-template-columns: 1fr;
  display: grid;
  margin-bottom: 1.5em;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1em;
    border-left: 5px solid
      ${_ref=>{let{active,theme}=_ref;return active?theme.colors.danger:'transparent';}};
  }
`;export const Heading=styled.span`
  margin: 0;
  font-size: ${_ref2=>{let{theme}=_ref2;return theme.fonts.fontSizes.small;}};
  text-transform: uppercase;
  color: ${_ref3=>{let{theme}=_ref3;return theme.colors.primary;}};
`;export const LinkWithIcon=styled.span`
  margin-left: 0.5em;
  cursor: pointer;
  text-decoration: underline;
`;export const LinkWithoutIcon=styled.span`
  cursor: pointer;
  text-decoration: underline;
`;export const LinkRow=styled.div``;