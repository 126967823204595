import _styled,{css as _css}from"styled-components";import{RGBToRGBA,Transparencies}from'@utils/theme';export const BaseButton=_styled.button.withConfig({displayName:"styles__BaseButton",componentId:"sc-47r0l6-0"})(["display:flex;justify-content:center;width:100%;text-decoration:none;text-transform:uppercase;letter-spacing:0.1em;padding:1rem 2em;border-radius:8px;font-weight:300;font-size:",";transition:0.25s ease;cursor:pointer;&:focus-visible{outline:4px dotted var(--color-accent);outline-offset:4px;border-radius:var(--border-radius);}"],_ref=>{let{theme}=_ref;return theme.fonts.fontSizes.small;});const navigationButtonMixin=_css`
  border: 2px solid transparent;
  background-color: ${_ref2=>{let{theme}=_ref2;return theme.colors.secondary;}};
  color: white;

  &:hover {
    border: 2px solid ${_ref3=>{let{theme}=_ref3;return theme.colors.danger;}};
  }

  &:active,
  &:focus {
    border: 2px solid ${_ref4=>{let{theme}=_ref4;return theme.colors.primary;}};
  }

  &:disabled {
    border: 2px solid transparent;
    background-color: ${_ref5=>{let{theme}=_ref5;return theme.colors.disabled;}};
    color: ${RGBToRGBA('rgb(255,255,255)',Transparencies.P50)};
  }
`;const navigationButtonGhostMixin=_css`
  border: 2px solid ${_ref6=>{let{theme}=_ref6;return theme.colors.secondary;}};
  background-color: white;
  font-weight: ${_ref7=>{let{theme}=_ref7;return theme.fonts.fontWeights.regular;}};
  color: ${_ref8=>{let{theme}=_ref8;return theme.colors.secondary;}};

  &:hover {
    border: 2px solid ${_ref9=>{let{theme}=_ref9;return theme.colors.danger;}};
  }

  &:active,
  &:focus {
    border: 2px solid ${_ref10=>{let{theme}=_ref10;return theme.colors.primary;}};
    background-color: ${_ref11=>{let{theme}=_ref11;return RGBToRGBA(theme.colors.primary,Transparencies.P10);}};
  }

  &:disabled {
    border: 2px solid ${_ref12=>{let{theme}=_ref12;return theme.colors.disabled;}};
    background-color: transparent;
    color: ${_ref13=>{let{theme}=_ref13;return theme.colors.disabled;}};
  }
`;const contentButtonMixin=_css`
  background: #11ddc4;
  color: #182129;
  border: 2px solid #11ddc4;

  &:hover {
    outline-width: 2px;
    outline-offset: -2px;
    background-color: #fff;
  }
`;const deleteButtonMixin=_css`
  border: 2px solid ${_ref14=>{let{theme}=_ref14;return theme.colors.danger;}};
  background-color: ${_ref15=>{let{theme}=_ref15;return theme.colors.danger;}};
  color: white;

  &:hover {
    background-color: white;
    color: ${_ref16=>{let{theme}=_ref16;return theme.colors.danger;}};
  }

  &:active,
  &:focus {
    border: 2px solid ${_ref17=>{let{theme}=_ref17;return theme.colors.danger;}};
  }
`;const contentButtonGhostMixin=_css``;export const StyledButton=_styled(BaseButton).withConfig({displayName:"styles__StyledButton",componentId:"sc-47r0l6-1"})(["",";"],_ref18=>{let{variation,flavour}=_ref18;switch(variation){case'standard':switch(flavour){case'navigation':return navigationButtonMixin;case'content':return contentButtonMixin;default:return contentButtonMixin;}case'ghost':switch(flavour){case'navigation':return navigationButtonGhostMixin;case'content':return contentButtonGhostMixin;default:return contentButtonGhostMixin;}case'delete':return deleteButtonMixin;default:return contentButtonMixin;}});